var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"sticky-menu-nav justify-content-around list-unstyled mb-0 px-5"},[_c('div',{staticClass:"row"},[_c('router-link',{staticStyle:{"width":"20%"},attrs:{"to":_vm.getLink('/dashboard')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"flaticon-squares-1 icon-md",class:[isActive && 'text-primary']})]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.i18n.dashboard)+" ")])])])]}}])}),_c('router-link',{staticStyle:{"width":"20%"},attrs:{"to":_vm.getLink('/detail')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"fas fa-clipboard icon-lg",class:[isActive && 'text-primary']})]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.farmDetailInfo)+" ")])])])]}}])}),_c('router-link',{staticStyle:{"width":"20%"},attrs:{"to":_vm.getLink('/time-series')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"flaticon2-graph",class:[isActive && 'text-primary']})]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.farmTimeSeriesInfo)+" ")])])])]}}])}),_c('router-link',{staticStyle:{"width":"20%"},attrs:{"to":_vm.getLink('/farm-photo-registration')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon"},[_c('i',{staticClass:"flaticon2-photograph",class:[isActive && 'text-primary']})]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.farmPhoto)+" ")])])])]}}])}),_c('router-link',{staticStyle:{"width":"20%"},attrs:{"to":_vm.getLink('/faq')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon",staticStyle:{"position":"relative","top":"5px"}},[_c('i',{staticClass:"flaticon2-chat icon-lg",class:[isActive && 'text-primary']})]),_c('span',{staticClass:"menu-text"},[_vm._v(" FAQ ")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }