<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
    :style="{
      backgroundImage: `url(${backgroundImage})`
    }"
  >

    <!--begin::Logo-->
    <router-link
        :to="'/portal'"
        v-slot="{ href, navigate }"
    >
    <a :href="href" class="logo" @click="navigate">
      <img alt="Logo" :src="headerLogo" />
    </a>
    </router-link>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <button
        v-show="!noneView"
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <button
        class="btn btn-hover-text-primary p-0 ml-4"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
        @click="userIconClicked"
      >
      <div>
        <span class="flaticon2-user font-size-h2 text-white"></span>
        <i v-if="newPush" class="fas fa-circle fa-sm text-danger pr-0 align-top"></i>
      </div>
      </button>
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';

export default {
  name: "KTHeaderMobile",
  components: {},
  data(){
    return{
      newPush: false,
    }
  },
  props: {
    totalUnread: Number,
    userIconClicked: Function
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Portal", "icon": 'fas fa-smile-beam' }]);

    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    noneView() {
      return this.$route.path.startsWith('/portal') || this.$route.path.startsWith('/profile') || window.location.href.split('/').indexOf('smartfarm') == -1;
    },
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/logos/logo.svg";
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    backgroundImage() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/bg/bg_top@3x.png";
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  },
  watch:{
    totalUnread(){
      if(this.totalUnread == 0){
        this.newPush = false
      }else{
        this.newPush= true
      }
    }
  }
};
</script>
