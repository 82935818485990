<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->

    <ul class="actions-list d-flex flex-center list-unstyled mb-0">
      <!--begin: Notifications -->
      <li v-if="isMobile" class="actions-item mr-3">
        <b-dropdown
          size="sm"
          toggle-class="topbar-item text-decoration-none"
          no-caret
          right
          no-flip
          variant="white"
          @toggle="dropDownShow = !dropDownShow"
        >
          <b-dropdown-text tag="div" class="min-w-md-350px">
            <form>
              <KTDropdownNotification
                :appPushList="appPushList"
                :unreadAppPushList="unreadAppPushList"
                :getAppPush="getAppPush"
                :getAppPushUnread="getAppPushUnread"
                :setPageNo="setPageNo"
                :setPageNoUnread="setPageNoUnread"
                :setUnread="setUnread"
                :setRead="setRead"
                :setReadAll="setReadAll"
                :unreadCount="unreadCount"
                :noMore="noMore"
                :noMoreUnread="noMoreUnread"
                :resetAppPush="resetAppPush"
                :resetLoading="resetLoading"
              ></KTDropdownNotification>
            </form>
          </b-dropdown-text>
          <template v-slot:button-content>
            <i class="fas fa-bell text-primary pr-0"></i>
            <span class="label label-sm label-rounded label-danger">{{unreadCount}}</span>
          </template>
        </b-dropdown>
      </li>
      <!--end: Notifications -->
      <!--begin: Menual -->
      <li v-if="farmConnected" class="actions-item mr-3">
        <b-button
            class="btn-icon font-weight-bold "
            variant="white"
            @click="askMenulDownload()"
        >
          <i class="flaticon2-information text-primary pr-0"></i>
        </b-button>
      </li>
      <!--end: Menual -->
      <!--begin: Translation -->
      <li class="actions-item mr-3 ">
        <b-button
          class="btn-icon"
          variant="white"
        >
          <b-dropdown variant="white" toggle-class="text-decoration-none py-3 pl-1 pr-1" no-caret>
            <template #button-content>
              <i class="fas fa-globe text-primary "></i>
            </template>
            <KTDropdownLanguage></KTDropdownLanguage>
          </b-dropdown>
        </b-button>
      </li>
      <!--end: Translation -->
      <!--begin: User -->
      <li class="actions-item mr-3">
        <b-button
            class="btn-icon"
            variant="white"
            @click="goProfile"
        >
          <i class="flaticon2-user text-primary pr-0"></i>
        </b-button>
      </li>
      <!--end: User -->
      <!--begin: Logout -->
      <li v-if="isAuthenticated" class="actions-item">
        <b-button
          class="btn-icon"
          variant="white"
          @click="logout"
        >
          <i class="fas fa-power-off text-primary"></i>
        </b-button>
      </li>

      <!--end: Logout -->
    </ul>

  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.test{
  position: relative;
}
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }
  .aaa{
    width: 40px;
    height: 40px;
    border-radius: 15px;
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from '@/view/layout/extras/dropdown/DropdownNotification.vue';
// import KTDropdownQuickAction from '@/view/layout/extras/dropdown/DropdownQuickAction.vue';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
import {LOGOUT} from '@/core/services/store/auth.module';
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue';
import {mapGetters} from 'vuex';
import {ACT_GET_APP_PUSH, ACT_GET_MY_FARMS} from "@/core/services/variable";
// import {ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module'
import {getItems, lengthCheck, utcToDate} from "@/core/services/funcs";
// import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "KTTopbar",
  props:{getPush: Number, totalUnreadCheck: Function},
  beforeMount(){
    this.$store.dispatch(ACT_GET_MY_FARMS).then(resp => {
      if(getItems(resp).length == 0){
        this.farmConnected = false
      }
    })
  },
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      appPushList: [],
      unreadAppPushList: [],
      newPushList: [],
      unreadCount: 0,
      pageNo: 1,
      pageNoUnread: 1,
      noMore: false,
      noMoreUnread: false,
      farmConnected: true,
      dropDownShow: false,
      resetLoading: false
    };
  },
  components: {
    KTDropdownNotification,
    // KTDropdownQuickAction,
    KTQuickUser,
    KTDropdownLanguage,

    // KTQuickPanel
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isMobile"]),
    // getLanguageFlag() {
    //   return this.onLanguageChanged();
    // },
  },
  watch:{
    getPush(){
      this.resetAppPush()
    },
    dropDownShow(val){
      if (val === true) {
        this.resetAppPush()
      }
    }
  },
  methods: {
    resetAppPush() {
      this.resetLoading = true
      this.pageNo = 1
      this.pageNoUnread = 1
      this.appPushList = []
      this.unreadAppPushList = []
      this.getAppPush()
      this.getAppPushUnread()
    },
    logout() {
      this.$store.dispatch(LOGOUT)
      this.$router.push({ name: "signin" });
    },
    goProfile(){
      this.$router.push({ name: "Profile" });
    },
    getAppPush(){
      this.$store.dispatch(ACT_GET_APP_PUSH, {pageNo: this.pageNo, recipientMno: this.$store.state.auth.mno}).then(resp => {
        if (lengthCheck(resp)) {
          this.newPushList = [...getItems(resp)]

          getItems(resp).forEach((data, idx) => {
            this.newPushList[idx].notiSendFinishDt = utcToDate(data.notiSendFinishDt, 'yyyy.MM.dd hh:mm')
          })
          this.newPushList.forEach(item =>{
            this.appPushList.push(item)
          })
          if (resp.items.length < 50) {
            this.noMore = true
          }
        }else{
          this.noMore = true
        }
        this.resetLoading = false
      })
    },
    getAppPushUnread(){
      this.$store.dispatch(ACT_GET_APP_PUSH, {pageNo: this.pageNoUnread, recipientMno: this.$store.state.auth.mno, notiSendStDcd: 1031003}).then(resp => {
        this.unreadCount = 0
        if (lengthCheck(resp)) {
          this.newPushList = [...getItems(resp)]

          getItems(resp).forEach((data, idx) => {
            this.newPushList[idx].notiSendFinishDt = utcToDate(data.notiSendFinishDt, 'yyyy.MM.dd hh:mm')
          })
          this.newPushList.forEach(item =>{
            this.unreadAppPushList.push(item)
          })
          this.unreadCount = resp.unreadNotiCount
          this.totalUnreadCheck(this.unreadCount)
          if (resp.items.length < 50) {
            this.noMoreUnread = true
          }
        }else{
          this.totalUnreadCheck(this.unreadCount)
          this.noMoreUnread = true
        }
      })
    },
    setPageNo(){
      this.pageNo += 1
    },
    setPageNoUnread(){
      this.pageNoUnread += 1
    },
    setRead(idx){
      this.appPushList[idx].notiSendStDcd = "1031005"
    },
    setReadAll(){
      for(let i = 0; i < this.appPushList.length; i++){
        this.appPushList[i].notiSendStDcd = "1031005"
      }
    },
    setUnread(){
      this.unreadAppPushList = []
      this.pageNoUnread = 1
      this.getAppPushUnread()
    },
    askMenulDownload(){
      window.open('https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/menual/%ED%86%B5%ED%95%A9%EA%B4%80%EC%A0%9C_%EC%82%AC%EC%9A%A9%EC%9E%90_%EC%9A%B4%EC%98%81%EB%A9%94%EB%89%B4%EC%96%BC(20230613).pdf')
    }
  }
}
</script>
