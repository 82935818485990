var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.noneView && _vm.farmId)?_c('ul',{staticClass:"menu-nav text-center"},[_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/dashboard'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"flaticon-squares-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.i18n.dashboard))])])])]}}],null,false,673397546)}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-open': _vm.subOpen.info},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSubMenu('info')}}},[_c('i',{staticClass:"fas fa-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.farm.info)+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"1000px"}},[_c('ul',{staticClass:"menu-content"},[_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/detail'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.search)+" ")])])])]}}],null,false,2351738610)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/setting'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.cultivationEnvControl)+" ")])])])]}}],null,false,325005418)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/crops'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.cultivationCropMangement)+" ")])])])]}}],null,false,987205996)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/yield-plan-information'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.yieldPlanInfo)+" ")])])])]}}],null,false,1226166844)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/environment-measurement-information'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.EnvMeasurementInfo)+" ")])])])]}}],null,false,3398809221)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/farm-calendar'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.farmCalendar)+" ")])])])]}}],null,false,2425949174)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/farm-photo-registration'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.farmPhotoRegistration)+" ")])])])]}}],null,false,3120014169)})],1)])])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-open': _vm.subOpen.analysis},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSubMenu('analysis')}}},[_c('i',{staticClass:"fas fa-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.farm.analysis)+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"1000px"}},[_c('ul',{staticClass:"menu-content"},[_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/time-series'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"}),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(_vm._s(_vm.i18n.farm.timeSeriesInfo)+" ")])])])]}}],null,false,364734412)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/alert-log-time-series'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"}),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.alertLogTimeSeriesInfo)+" ")])])])]}}],null,false,2109720813)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/notification-action'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"}),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.farmDeviationStatusInfo)+" ")])])])]}}],null,false,3232179910)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/weekly-report'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"}),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.farm.weeklyreport)+" ")])])])]}}],null,false,2440600224)})],1)])])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-open': _vm.subOpen.help},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSubMenu('help')}}},[_c('i',{staticClass:"fas fa-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.farm.help)+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"1000px"}},[_c('ul',{staticClass:"menu-content"},[_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/as'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"}),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.claim)+" ")])])])]}}],null,false,685875771)}),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/faq'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot farm-mobile-dot"}),_c('span',{staticClass:"menu-text text-white font-weight-bold font-size-lg"},[_vm._v(" "+_vm._s(_vm.i18n.faq)+" ")])])])]}}],null,false,764591143)})],1)])])]),_c('router-link',{attrs:{"to":'/smartfarm/' + _vm.farmId +'/notice'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
return [_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren('notice') },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-flag"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.i18n.notice)+" ")])])])]}}],null,false,819291716)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }