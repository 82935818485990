<template>
  <div
    class="subheader py-2 py-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >

      <div class="subheader-title d-flex align-items-center flex-wrap">
        <i v-if="iconClass" class="icon-xl ml-5 mr-5" :class="iconClass"></i>
<!--        <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Call.svg" />-->

        <h3 class="title text-dark font-weight-bold my-2">
          {{ title }}
        </h3>
        <ul
          class="breadcrumb breadcrumb-transparent p-0 my-2 font-size-lg"
        >
<!--          <li class="breadcrumb-item">-->
<!--            <router-link :to="'/'" class="subheader-breadcrumbs-home">-->
<!--              <span class="text-dark">Portal</span>-->
<!--            </router-link>-->
<!--          </li>-->

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-dark"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-dark" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div v-if="showPortalSearch">
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input v-model="searchInput" @update="onSearchUpdated" type='search' debounce="300" placeholder="placeholder" @keyup.enter="onSearchUpdated(searchInput)"></b-form-input>
        </b-input-group>
      </div>
      <Datepicker />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Datepicker from "@/view/layout/subheader/Datepicker.vue";
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    Datepicker
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    iconClass() {
      if(this.breadcrumbs && this.breadcrumbs.length){
        return this.breadcrumbs[0].icon;
      }
      return '';
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
        showPortalSearch() {
      if (this.$route.path === '/portal') {
        return true
      }
      return false
    }
  },
  data() {
    const defaultSearchInput = this.$route.query.search || ''
    return {
      searchInput: defaultSearchInput,
      placeholder: i18n.t('portal.placeholder')
    }
  },
  methods: {
    onSearchUpdated(search) {
      if (this.$route.query.search !== search) {
        this.$router.push({ path: '/portal', query: { search }})
      }
    }
  }
};
</script>
