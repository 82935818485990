<template>
  <ul class="sticky-menu-nav justify-content-around list-unstyled mb-0 px-5">
    <div class="row">
      <router-link
        :to="getLink('/dashboard')"
        v-slot="{ href, navigate, isActive, isExactActive }"
        style="width: 20%;"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-icon">
              <i class="flaticon-squares-1 icon-md" :class="[isActive && 'text-primary']"></i>
            </span>
            <span class="menu-text">{{i18n.dashboard}} </span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="getLink('/detail')"
        v-slot="{ href, navigate, isActive, isExactActive }"
        style="width: 20%;"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-icon">
              <i class="fas fa-clipboard icon-lg" :class="[isActive && 'text-primary']"></i>
            </span>
            <span class="menu-text"> {{i18n.farmDetailInfo}} </span>
          </a>
        </li>
      </router-link>

      <router-link
        :to="getLink('/time-series')"
        v-slot="{ href, navigate, isActive, isExactActive }"
        style="width: 20%;"
      >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon">
            <i class="flaticon2-graph" :class="[isActive && 'text-primary']"></i>
          </span>
          <span class="menu-text"> {{i18n.farmTimeSeriesInfo}} </span>
        </a>
      </li>
      </router-link>

      <router-link
        :to="getLink('/farm-photo-registration')"
        v-slot="{ href, navigate, isActive, isExactActive }"
        style="width: 20%;"
      >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon">
            <i class="flaticon2-photograph" :class="[isActive && 'text-primary']"></i>
          </span>
          <span class="menu-text"> {{i18n.farmPhoto}} </span>
        </a>
      </li>
      </router-link>

      <router-link
        :to="getLink('/faq')"
        v-slot="{ href, navigate, isActive, isExactActive }"
        style="width: 20%;"
      >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon" style="position: relative; top: 5px;">
            <i class="flaticon2-chat icon-lg" :class="[isActive && 'text-primary']"></i>
          </span>
          <span class="menu-text"> FAQ </span>
        </a>
      </li>
      </router-link>
    </div>

  </ul>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "StickyMenu",
  data(){
    return{
      i18n:{
        dashboard: i18n.t("topNavBar.dashboard"),
        farmDetailInfo: i18n.t("topNavBar.farmDetailInfo"),
        farmTimeSeriesInfo: i18n.t("topNavBar.farmTimeSeriesInfo"),
        farmPhoto: i18n.t("topNavBar.farmPhoto"),
      },
    }
  },

  // computed: {
  //   iconDashboard() {
  //     return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_sticky_dashboard.svg";
  //   },
  //   iconFarmDetail() {
  //     return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_sticky_farm.svg";
  //   },
  //   iconAs() {
  //     return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_sticky_as.svg";
  //   },
  //   iconCustomer() {
  //     return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_sticky_customer.svg";
  //   },
  //   iconNotice() {
  //     return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_sticky_notice.svg";
  //   }
  // },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1
    },
    getLink(path) {
      const currentPath = this.$route.path

      const regex = /\/smartfarm\/([^/]+)/
      const matches = currentPath.match(regex)

      if (matches && matches.length >= 2) {
        const valueAfterSmartfarm = matches[1]
        return '/smartfarm/' + valueAfterSmartfarm + path
      }

      return currentPath;
    }
  }
};
</script>
