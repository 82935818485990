<template>
  <!-- begin:: Footer -->
<!--  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">-->
  <div class="footer bg-white py-4" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <a href="/" class="logo">
          <img alt="Logo" :src="footerLogo" />
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    /**
     * Get header logo
     * @returns {string}
     */

    footerLogo() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/logos/logo_footer.png";
    },
  }
};
</script>
