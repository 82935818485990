<template>
  <!-- begin:: Sticky bottom -->
  <div v-if="!noneView" class="sticky-bottom bg-white" id="sticky-bottom" style="z-index: 5">
    <StickyMenu></StickyMenu>
  </div>
  <!-- end:: Sticky bottom -->
</template>

<script>
import StickyMenu from '@/view/layout/sticky/Menu.vue';

export default {
  name: "KTSticky",
  components: {
    StickyMenu
  },
  computed: {
    noneView() {
      return this.$route.path.startsWith('/portal') || this.$route.path.startsWith('/profile') || window.location.href.split('/').indexOf('smartfarm') == -1;
    },
  }
};
</script>
