<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">{{i18n.farmAlarm}}</span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            @click="tabIndex = 1 , showDetail = false"
            data-tab="0"
            :class="`nav-link ${tabIndex == 1 ? 'active': ''}`"
            data-toggle="tab"
            role="tab"
          >
            {{i18n.totalAlarm}}
          </a>
        </li>
        <li class="nav-item">
          <a
              @click="tabIndex = 2, showDetailUnread = false"
              data-tab="1"
              :class="`nav-link ${tabIndex == 2 ? 'active': ''}`"
              data-toggle="tab"
              role="tab"
          >
            {{i18n.unreadAlarm}} ({{unreadCount}})
          </a>
        </li>
      </ul>
    </div>

    <b-overlay :show="resetLoading" rounded="sm">
      <div class="text-right py-2">
        <a v-show="tabIndex==1 && appPushList.length !== 0 && !showDetail" @click="resetAppPush"><small class="p-2">{{i18n.refresh}}</small></a>
        <a v-show="tabIndex==2 && unreadAppPushList.length !== 0 && !showDetailUnread" @click="readAllAppPush"><small class="py-2">{{i18n.readAll}}</small></a>
        <small v-show="tabIndex==2 && unreadAppPushList.length !== 0 && !showDetailUnread">{{' /'}}</small>
        <a v-show="tabIndex==2 && unreadAppPushList.length !== 0 && !showDetailUnread" @click="resetAppPush"><small class="p-2">{{i18n.refresh}}</small></a>
      </div>
      <b-tabs no-key-nav no-nav-style nav-class="d-none">
        <b-tab :active="tabIndex==1" class="p-1">
          <perfect-scrollbar
            class="navi navi-hover scroll my-4"
            style="max-height: 40vh; position: relative;"
            v-show="!showDetail"
          >
            <template v-for="(item, i) in appPushList">
              <div @click="getDetail(item, false)" class="navi-item border-bottom" :key="i">
                <div class="navi-link">
                  <div class="navi-text" v-bind:idx="i">
                    <div v-if="item.notiSendStDcd === '1031003' && item.notiTitle != null" class="font-size-lg font-weight-bold">
                      {{ item.notiTitle }}
                    </div>
                    <div v-if="item.notiSendStDcd === '1031005' && item.notiTitle != null" class="font-size-lg font-weight">
                      {{ item.notiTitle }}
                    </div>
                    <div v-if="item.notiSendStDcd === '1031003'" class="font-size-lg font-weight-bold">
                      {{ item.notiContent.split('\r\n\r\n')[1] + ' ' }} ...
                    </div>
                    <div v-if="item.notiSendStDcd === '1031005'" class="font-size-lg font-weight">
                      {{ item.notiContent.split('\r\n\r\n')[1] + ' ' }} ...
                    </div>
                    <div class="font-size-lg font-weight">
                      {{ item.notiSendFinishDt }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="appPushList.length == 0" class="py-10 text-center text-secondary">
              {{i18n.noAlarm}}
            </div>
            <b-button v-if="!loading && noMore == false && !appPushZero" @click="infiniteHandler" class="btn btn-secondary col-12 p-2">
              + {{i18n.more}}
            </b-button>
            <b-button v-if="loading && noMore == false  && !appPushZero" class="btn btn-secondary col-12 p-2">
              <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>{{i18n.loading}}...
            </b-button>
          </perfect-scrollbar>

          <perfect-scrollbar
            class="scroll pr-7 mr-n7"
            style="max-height: 40vh; position: relative;"
            v-show="showDetail"
          >
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6 p-5">
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <div v-for="(content, idx) in detailAppPush.notiContent.split('\r\n\r\n')" :key="idx" :class="`text-dark text-hover-primary font-size-lg ${idx !== 0 ?  'text-muted mt-2' : ''}`">
                  {{ content }}
                </div>
                <br><br>
                <div>
                <a v-if="detailAppPushStopURL != ''" @click="gotoDeviationStatus()" class="text-hover-primary mb-1 font-size-lg">
                  {{i18n.gotoDeviationStatus}}
                </a>
                <b-button class="btn btn-secondary float-right" @click="showDetail = false">
                <span class="fas fa-reply"></span>
                </b-button>
                </div>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </perfect-scrollbar>
        </b-tab>

        <b-tab :active="tabIndex==2" class="p-1">
          <perfect-scrollbar
              class="navi navi-hover scroll my-4"
              style="max-height: 40vh; position: relative;"
              v-show="!showDetailUnread"
          >
            <template v-for="(item, i) in unreadAppPushList">
              <div v-on:click="getDetail(item, true)" class="navi-item" v-bind:key="i">
                <div class="navi-link">
                  <div class="navi-text" v-bind:idx="i">
                    <div v-if="item.notiTitle != null" class="font-size-lg font-weight-bold">
                      {{ item.notiTitle }}
                    </div>
                    <div class="font-size-lg font-weight-bold">
                      {{ item.notiContent.split('\r\n\r\n')[1] + ' '}} ...
                    </div>
                    <div class="font-size-lg font-weight">
                      {{ item.notiSendFinishDt }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="unreadAppPushList.length == 0" class="py-10 text-center text-secondary">
              {{i18n.noUnreadAlarm}}
            </div>
            <b-button v-if="!loading && noMoreUnread == false && !appPushZeroUnread" @click="infiniteHandlerUnread" class="btn btn-secondary col-12 p-2">
              + {{i18n.more}}
            </b-button>
            <b-button v-if="loading && noMoreUnread == false && !appPushZeroUnread" class="btn btn-secondary col-12 p-2">
              <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>{{i18n.loading}}...
            </b-button>
          </perfect-scrollbar>

          <perfect-scrollbar
              class="scroll pr-7 mr-n7"
              style="max-height: 40vh; position: relative;"
              v-show="showDetailUnread"
          >
              <!--begin::Item-->
              <div class="d-flex align-items-center mb-6 p-5">
                <!--begin::Text-->
                <div class="d-flex flex-column font-weight-bold">
                  <div v-for="(content, idx) in detailAppPush.notiContent.split('\r\n\r\n')" :key="idx" :class="`text-dark text-hover-primary mb-1 font-size-lg ${idx !== 0 ?  'text-muted mt-2' : ''}`">
                    {{ content }}
                  </div>
                  <br><br>
                  <div>
                    <a v-if="detailAppPushStopURL != ''" @click="gotoDeviationStatus()" class="text-hover-primary mb-1 font-size-lg">
                    {{i18n.gotoDeviationStatus}}
                  </a>
                  <b-button class="btn btn-secondary float-right" @click="showDetailUnread = false">
                  <span class="fas fa-reply"></span>
                  </b-button>
                  </div>
                </div>
                <!--end::Text-->
              </div>
                <!--end::Item-->
          </perfect-scrollbar>
        </b-tab>
      </b-tabs>
      <template #overlay>
        <div class="text-center h-100">
          <b-icon icon="stopwatch" font-scale="1" animation="cylon"></b-icon>
          <p id="cancel-label">Loading...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>


<script>
import {ACT_UPDATE_APP_PUSH_ST, ACT_UPDATE_ALL_APP_PUSH} from "@/core/services/variable";
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "KTDropdownNotification",
  props: {
    appPushList: Array,
    unreadAppPushList: Array,
    getAppPush: Function,
    getAppPushUnread: Function,
    setPageNo: Function,
    setPageNoUnread: Function,
    setUnread: Function,
    setRead: Function,
    setReadAll: Function,
    unreadCount: Number,
    noMore: Boolean,
    noMoreUnread: Boolean,
    resetAppPush: Function,
    resetLoading: Boolean
  },
  data() {
    return {
      tabIndex: 1,
      detailAppPush: {notiContent: ""},
      detailAppPushStopURL: '',
      showDetail: false,
      showDetailUnread: false,
      loading: false,
      i18n:{
        farmAlarm: i18n.t('dropdownNotification.farmAlarm'),
        totalAlarm: i18n.t('dropdownNotification.totalAlarm'),
        unreadAlarm: i18n.t('dropdownNotification.unreadAlarm'),
        noAlarm: i18n.t('dropdownNotification.noAlarm'),
        noUnreadAlarm: i18n.t('dropdownNotification.noUnreadAlarm'),
        gotoDeviationStatus: i18n.t('dropdownNotification.gotoDeviationStatus'),
        readAll: i18n.t('dropdownNotification.readAll'),
        more: i18n.t('dropdownNotification.more'),
        loading: i18n.t('dropdownNotification.loading'),
        refresh: i18n.t('dropdownNotification.refresh')
      }
    }
  },
  methods: {
    readAllAppPush(){
      this.$store.dispatch(ACT_UPDATE_ALL_APP_PUSH).then(() => {
        this.setReadAll()
        this.setUnread()
      })
    },
    gotoDeviationStatus(){
      const path = this.detailAppPushStopURL.split('smartfarm').slice(-1)[0]
      this.$router.push({path: '/smartfarm' + path})
    },
    getDetail(item, unread){
      this.detailAppPush= {notiContent: ""}
      this.detailAppPushStopURL= ''

      if(unread === true){
        this.showDetailUnread = true
      }else{
        this.showDetail = true
      }
      this.detailAppPush = item

      if(this.detailAppPush.notiSendStDcd == '1031003'){
        this.$store.dispatch(ACT_UPDATE_APP_PUSH_ST, this.detailAppPush.sendSeq).then(() => {
          this.appPushList.forEach((push,idx) =>{
            if(push.sendSeq == this.detailAppPush.sendSeq){
              this.setRead(idx)
            }
          })
          this.setUnread()
        })
      }
      this.detailAppPushStopURL = this.detailAppPush.clickAction
    },
    infiniteHandler() {
      this.loading = true
      this.setPageNo()
      this.getAppPush()
    },
    infiniteHandlerUnread() {
      this.loading = true
      this.setPageNoUnread()
      this.getAppPushUnread()
    },
  },
  computed: {
    backgroundImage() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/misc/bg-1.jpg";
    },
    appPushZero(){
      return this.appPushList.length == 0
    },
    appPushZeroUnread(){
      return this.unreadAppPushList.length == 0
    }
  },
  watch : {
    appPushList(){
      this.loading = false
    },
    unreadAppPushList(){
      this.loading = false
    }
  }
};
</script>

<style>
  a :hover {
    cursor: pointer;
  }
</style>
