<template>
  <ul v-if="!noneView && farmId" class="menu-nav text-center">
    <router-link
      :to="'/smartfarm/' + farmId +'/dashboard'"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="flaticon-squares-1"></i>
          <span class="menu-text">{{ i18n.dashboard }}</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{'menu-item-open': subOpen.info}"
    >
      <a href="javascript:;" class="menu-link" v-on:click.prevent.stop="toggleSubMenu('info')">
<!--      <a href="javascript:;" class="menu-link">-->
        <i class="fas fa-clipboard"></i>
        <span class="menu-text"> {{ i18n.farm.info }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <router-link
              :to="'/smartfarm/' + farmId +'/detail'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.search }} </span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="'/smartfarm/' + farmId +'/setting'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.cultivationEnvControl }} </span>
                </a>
              </li>
            </router-link>

            <router-link
              :to="'/smartfarm/' + farmId +'/crops'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.cultivationCropMangement }} </span>
                </a>
              </li>
            </router-link>


            <router-link
              :to="'/smartfarm/' + farmId +'/yield-plan-information'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.yieldPlanInfo }} </span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/environment-measurement-information'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.EnvMeasurementInfo }} </span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/farm-calendar'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.farmCalendar }} </span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/farm-photo-registration'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot farm-mobile-dot"><span></span></i>
                  <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.farm.farmPhotoRegistration }} </span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{'menu-item-open': subOpen.analysis}"
    >
      <a href="javascript:;" class="menu-link" v-on:click.prevent.stop="toggleSubMenu('analysis')">
<!--      <a href="javascript:;" class="menu-link">-->
        <i class="fas fa-clipboard"></i>
        <span class="menu-text"> {{ i18n.farm.analysis }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <router-link
              :to="'/smartfarm/' + farmId +'/time-series'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot farm-mobile-dot"></i>
                <span class="menu-text text-white font-weight-bold font-size-lg">{{ i18n.farm.timeSeriesInfo }} </span>
              </a>
            </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/alert-log-time-series'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot farm-mobile-dot"></i>
                <span class="menu-text text-white font-weight-bold font-size-lg"> {{  i18n.farm.alertLogTimeSeriesInfo }} </span>
              </a>
            </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/notification-action'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot farm-mobile-dot"></i>
                <span class="menu-text text-white font-weight-bold font-size-lg"> {{  i18n.farm.farmDeviationStatusInfo }} </span>
              </a>
            </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/weekly-report'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot farm-mobile-dot"></i>
                <span class="menu-text text-white font-weight-bold font-size-lg"> {{  i18n.farm.weeklyreport }} </span>
              </a>
            </li>
            </router-link>
          </ul>
        </div>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{'menu-item-open': subOpen.help}"
    >
      <a href="javascript:;" class="menu-link" v-on:click.prevent.stop="toggleSubMenu('help')">
<!--      <a href="javascript:;" class="menu-link">-->
        <i class="fas fa-clipboard"></i>
        <span class="menu-text"> {{ i18n.farm.help }} </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 1000px;">
          <ul class="menu-content">
            <router-link
              :to="'/smartfarm/' + farmId +'/as'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot farm-mobile-dot"></i>
                <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.claim }} </span>
              </a>
            </li>
            </router-link>
            <router-link
              :to="'/smartfarm/' + farmId +'/faq'"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot farm-mobile-dot"></i>
                <span class="menu-text text-white font-weight-bold font-size-lg"> {{ i18n.faq }} </span>
              </a>
            </li>
            </router-link>
          </ul>
        </div>
      </div>
    </li>

    <router-link
      :to="'/smartfarm/' + farmId +'/notice'"
      v-slot="{ href, navigate }"
    >
    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('notice') }"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <i class="fas fa-flag"></i>
        <span class="menu-text"> {{ i18n.notice }} </span>
      </a>
    </li>
    </router-link>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'KTMenu',
  computed: {
    ...mapGetters(['farmId']),
    noneView() {
      return this.$route.path.startsWith('/portal') || this.$route.path.startsWith('/profile');
    }
  },
  data() {
    return {
      i18n: {
        dashboard: i18n.t('dashboard.title'),
        farm: {
          info: i18n.t('topNavBar.farm.info'),
          analysis: i18n.t('topNavBar.farm.analysis'),
          help: i18n.t('topNavBar.farm.help'),
          search: i18n.t('topNavBar.farm.search'),
          cropManagement :  i18n.t('topNavBar.farm.cropManagement'),
          yieldInfo : i18n.t('topNavBar.farm.yieldInfo'),
          yieldPlanInfo : i18n.t('topNavBar.farm.yieldPlanInfo'),
          EnvMeasurementInfo: i18n.t('topNavBar.farm.EnvMeasurementInfo'),
          farmCalendar: i18n.t('topNavBar.farm.farmCalendar'),
          farmPhotoRegistration: i18n.t('topNavBar.farm.farmPhotoRegistration'),
          timeSeriesInfo: i18n.t('topNavBar.farm.timeSeriesInfo'),
          alertLogTimeSeriesInfo: i18n.t('topNavBar.farm.alertLogTimeSeriesInfo'),
          farmDeviationStatusInfo: i18n.t('topNavBar.farm.farmDeviationStatusInfo'),
          weeklyreport: i18n.t('topNavBar.farm.weeklyreport'),
          cultivationEnvControl: i18n.t('topNavBar.farm.cultivationEnvControl'),
          cultivationCropMangement: i18n.t('topNavBar.farm.cultivationCropMangement'),
        },
        claim: i18n.t('topNavBar.claim'),
        faq: i18n.t('topNavBar.faq'),
        notice: i18n.t('topNavBar.notice'),
      },
      subOpen: {
        info: false,
        analysis: false,
        help: false,
      }
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    toggleSubMenu(toggleNm){
      this.subOpen[toggleNm] = !this.subOpen[toggleNm]
      this.pushFirstSubMenu(toggleNm)
    },
    pushFirstSubMenu(toggleNm) {
      if (toggleNm === 'info') {
        if (this.$route.path.split('/').slice(-1)[0] !== 'detail'){
          this.$router.push('/smartfarm/' + this.farmId +'/detail')
        }
      } else if (toggleNm === 'analysis') {
        if (this.$route.path.split('/').slice(-1)[0] !== 'time-series'){
          this.$router.push('/smartfarm/' + this.farmId +'/time-series')
        }
      } else if (toggleNm === 'help') {
        if (this.$route.path.split('/').slice(-1)[0] !== 'as'){
          this.$router.push('/smartfarm/' + this.farmId +'/as')
        }
      }

    }
  },
};
</script>