<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
    :style="{
      backgroundImage: `url(${backgroundImage})`
    }"
  >
    <div
      class="container-fluid d-flex align-items-center"
    >
      <div class="flex-fill">

        <router-link
            :to="'/portal'"
            v-slot="{ href, navigate }"
        >
        <!--begin::Logo-->
        <a :href="href" class="logo" @click="navigate">
          <img alt="Logo" :src="headerLogo" />
        </a>
        </router-link>
        <!--end::Logo-->
      </div>
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper flex-fill"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- example static menu here -->
          <KTMenu></KTMenu>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <KTTopbar :getPush="getPush" :totalUnreadCheck="totalUnreadCheck"></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    KTMenu
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  props:{
    getPush: Number,
    totalUnreadCheck: Function
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/logos/logo.svg";
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    backgroundImage() {
      return "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/bg/bg_top@3x.png";
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
