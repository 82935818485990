<template>
  <div class="subheader-date">
    <span class="text font-weight-bold">{{ dateStr }}</span>
  </div>
</template>

<script>
import {todayToDate} from '@/core/services/funcs';

export default {
  name: "Layout",
  mounted() {
    this.clearDateInterval()

    this.dateStr = todayToDate()
    this.dateInterval = setInterval(()=> {
      this.dateStr = todayToDate()
    }, 1000)
  },
  destroyed() {
    this.clearDateInterval()
  },
  data(){
    return {
      dateInterval: null,
      dateStr: ''
    }
  },
  methods: {
    clearDateInterval(){
      if(this.dateInterval){
        clearInterval(this.dateInterval);
        this.dateInterval = null
      }
      this.dateStr = ''
    }
  }
};

</script>
